<template>
    <b-form @submit.prevent="onSubmit">
        <div>
            <h3>{{$t('legalInfo.moneyOrigin')}}</h3>
            <div>
                <form-input-checkbox
                    class="mb-1"
                    :id="'legalInfo.socialBenefits'"
                    v-model="socialBenefits"
                    :label="$t('legalInfo.socialBenefits')"
                    :validation="$v.socialBenefitsField"
                />
                <form-input v-show="socialBenefits"
                    :id="'legalInfo.socialBenefitsField'"
                    :label="$t('legalInfo.socialBenefitsField')"
                    v-model="socialBenefitsField"
                    :validation="$v.socialBenefitsField"
                />
            </div>
            <div>
                <form-input-checkbox
                    class="mb-1"
                    :id="'legalInfo.wage'"
                    v-model="wage"
                    :label="$t('legalInfo.wage')"
                    :validation="$v.employment"
                />
                <form-input v-show="wage"
                    :id="'legalInfo.employment'"
                    :label="$t('legalInfo.employment')"
                    v-model="employment"
                    :validation="$v.employment"
                />
            </div>

            <div>
                <form-input-checkbox
                    class="mb-1"
                    :id="'legalInfo.business'"
                    v-model="business"
                    :label="$t('legalInfo.business')"
                    :validation="$v.businessField"
                />
                <form-input v-show="business"
                    :id="'legalInfo.businessField'"
                    :label="$t('legalInfo.businessField')"
                    v-model="businessField"
                    :validation="$v.businessField"
                    :hint="$t('legalInfo.businessDesc')"
                />
            </div>

            <div>
                <form-input-checkbox
                    class="mb-1"
                    :id="'legalInfo.capital'"
                    v-model="capital"
                    :label="$t('legalInfo.capital')"
                    :validation="$v.capitalResources"
                />
                <div v-show="capital">
                    <form-input
                        :id="'legalInfo.capitalResources'"
                        :label="$t('legalInfo.capitalResources')"
                        v-model="capitalResources"
                        :validation="$v.capitalResources"
                    />
                    <div class="mt-4 mb-5 bg-cyrrus-gray-light p-3">
                        <uploader-row
                            class="bg-white p-3 card shadow-sm"
                            :title="$t('legalInfo.capitalUploadsHeading')"
                            :no-documents-text="$t('legalInfo.moneyOriginNoUploads')"
                            :url="$api.clientSessionUploads.uploaderUrl"
                            :uploads="uploads"
                            :removed-uploads="removedUploads"
                            :filtered-stored-uploads="filteredInfoUploads"
                            meta-field="capital"
                            @uploaded="onUploadUploaded"
                            @remove="onUploadRemove"
                            @restore="onUploadRestore"
                        />
                    </div>
                </div>
            </div>

            <div>
                <form-input-checkbox
                    class="mb-1"
                    :id="'legalInfo.other'"
                    v-model="other"
                    :label="$t('legalInfo.other')"
                    :validation="$v.otherResources"
                />
                <div v-show="other">
                    <form-input-textarea
                        :id="'legalInfo.otherResources'"
                        :label="$t('legalInfo.otherResources')"
                        v-model="otherResources"
                        :validation="$v.otherResources"
                    />
                    <div class="mt-4 mb-5 bg-cyrrus-gray-light p-3">
                        <uploader-row
                            class="bg-white p-3 card shadow-sm"
                            :title="$t('legalInfo.otherUploadsHeading')"
                            :no-documents-text="$t('legalInfo.moneyOriginNoUploads')"
                            :url="$api.clientSessionUploads.uploaderUrl"
                            :uploads="uploads"
                            :removed-uploads="removedUploads"
                            :filtered-stored-uploads="filteredInfoUploads"
                            :validation="$v.filteredInfoUploads"
                            meta-field="other"
                            @uploaded="onUploadUploaded"
                            @remove="onUploadRemove"
                            @restore="onUploadRestore"
                        />
                    </div>
                </div>
            </div>
            <p v-show="$v.moneyOrigin.$dirty && $v.moneyOrigin.$invalid" class="text-danger"><small>{{$t('validation.missingMoneyOrigin')}}</small></p>

            <div>
                <div v-if="clientType === 'PO'">
                    <h3 class="mt-5">{{$t('legalInfo.companyInfo')}}</h3>
                    <form-input-radio-group
                        :id="'legalInfo.auditionHistory'"
                        :label="$t('legalInfo.auditionHistory')"
                        :stacked="true"
                        v-model="auditionHistory"
                        :options="yesNoOptions"
                        :validation="$v.auditionHistory"
                    />

                    <form-input-radio-group
                        :id="'legalInfo.companyControlled'"
                        :label="$t('legalInfo.companyControlled')"
                        :stacked="true"
                        v-model="companyControlled"
                        :options="yesNoOptions"
                        :validation="$v.companyControlled"
                    />
                    <div v-if="companyControlled">
                        <form-input
                            :id="'legalInfo.companyControlledName'"
                            :label="$t('legalInfo.companyControlledName')"
                            v-model="companyControlledName"
                            :validation="$v.companyControlledName"
                        />
                        <form-input-textarea
                            :id="'legalInfo.companyControlledAddress'"
                            :label="$t('legalInfo.companyControlledAddress')"
                            v-model.trim="companyControlledAddress"
                            :validation="$v.companyControlledAddress"
                        />
                        <div class="mt-4 mb-5 bg-cyrrus-gray-light p-3">
                            <uploader-row
                                class="bg-white p-3 card shadow-sm"
                                :title="$t('legalInfo.companyControlledUploadsText')"
                                :no-documents-text="$t('legalInfo.companyControlledNoUploads')"
                                :url="$api.clientSessionUploads.uploaderUrl"
                                :uploads="uploads"
                                :removed-uploads="removedUploads"
                                :filtered-stored-uploads="filteredInfoUploads"
                                meta-field="companyControlled"
                                :validation="$v.filteredInfoUploads"
                                @uploaded="onUploadUploaded"
                                @remove="onUploadRemove"
                                @restore="onUploadRestore"
                            />
                        </div>
                    </div>
                </div>

                <h3 class="mt-5">{{$t('legalInfo.reasonAndAmount')}}</h3>
                <legal-info-reason
                    v-model="reasons"
                    :uploader-url="$api.clientSessionUploads.uploaderUrl"
                    :client-type="clientType"
                    :uploads="uploads"
                    :removed-uploads="removedUploads"
                    :filtered-info-uploads="filteredInfoUploads"
                    @uploaded="onUploadUploaded"
                    @remove="onUploadRemove"
                    @restore="onUploadRestore"
                />
                <p v-show="$v.reasons.$dirty && $v.reasons.$invalid" class="text-danger">
                    <small>{{$t('validation.missingReason')}}</small>
                </p>
                <form-input-textarea
                    v-if="reasons.includes('other')"
                    :id="'legalInfo.reasons.other'"
                    :label="$t('legalInfo.otherResources')"
                    v-model="reason"
                    :validation="$v.reason"
                    :hint="$t('legalInfo.reasonHint')"
                />

                <label for="legalInfo.transactionsAmount" class="mb-0 mt-4">
                    {{ $t('legalInfo.transactionsAmount') }}
                </label>
                <div class="row mt-0">
                    <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                        <form-input
                            :id="'legalInfo.transactionsAmount'"
                            v-model="computedTransactionsAmount"
                            :formatter="formatNumber"
                            :validation="$v.transactionsAmount"
                            :validation-messages="{
                                custom: $t('validation.transactionsAmountMinValue')
                            }"
                            render-as-group
                        >
                            <span class="input-group-text">CZK</span>
                        </form-input>
                    </div>
                </div>

                <form-input-radio-group
                    :id="'legalInfo.transactionsInterval'"
                    :label="$t('legalInfo.transactionsInterval')"
                    :options="transactionsIntervalOptions"
                    v-model="transactionsInterval"
                    :validation="$v.transactionsInterval"
                    :stacked="false"
                />
            </div>
        </div>

        <h3 class="mt-5">{{ $t('legalInfo.subheading') }}</h3>
        <div>
            <form-input-checkbox
                :label="$t('legalInfo.politician')"
                :id="'legalInfo.politician'"
                v-model="politician"
            />
            <form-input-checkbox
                :label="$t('legalInfo.criminalActivity')"
                :id="'legalInfo.criminalActivity'"
                v-model="criminalActivity"
                :validation="$v.criminalActivity"
            />
            <form-input-checkbox
                :label="$t('legalInfo.sanctionedBusiness')"
                :id="'legalInfo.sanctionedBusiness'"
                v-model="sanctionedBusiness"
            />
        </div>

        <h3 class="mt-5">{{$t('legalInfo.vopSubheading')}}</h3>
        <i18n path="legalInfo.vopLink" tag="p">
            <a :href="$t('legalInfo.vopLinkHref')" target='_blank'>{{$t('legalInfo.vopLinkText')}}</a>
        </i18n>
        <form-input-checkbox
            :id="'legalInfo.vop'"
            v-model="vop"
            :validation="$v.vop"
            :label="$t('legalInfo.vop')"
        />

        <h3 class="mt-5">{{$t('legalInfo.activateIBSubheading')}}</h3>
        <form-input-checkbox
            :id="'legalInfo.activateIB'"
            v-model="activateIB"
            :validation="$v.activateIB"
            :label="$t('legalInfo.activateIB')"
        />

        <div class="mt-5">
            <b-btn
                type="submit"
                variant="primary"
                class="mr-4"
            >
                {{ $t('app.submit') }}
            </b-btn>
            <b-btn
                variant="light"
                @click.prevent="onCancel"
            >
                {{ $t('app.cancel') }}
            </b-btn>
        </div>
    </b-form>
</template>

<script>
import { requiredIf, required, maxLength } from 'vuelidate/lib/validators'
import * as objectHash from 'object-hash'
import UploaderRow from '../../../../../Components/UploaderRow.vue'
import LegalInfoReason, { sortedReasons } from '../../../../../Components/LegalInfoReason.vue'

let tm

// eslint-disable-next-line no-useless-escape
const decimalNumberRegex = /^\d+([\.,]{1}\d{1,6})?$/
// eslint-disable-next-line no-useless-escape
const unrestrictedDecimalNumberRegex = /^\d+([\.,]{1}\d{1,})?$/

const getTrueFalseNull = (parameter) => {
    return (parameter === true || parameter === false) ? parameter : null
}

const resolveReason = (inputReason, _this) => {
    if (!inputReason) {
        return null
    }
    let newReason = inputReason
    for (const sortedReason of sortedReasons) {
        if (sortedReason !== 'other') {
            newReason = newReason.replace(new RegExp(`${_this.$t(`legalInfo.reasons.${sortedReason}`)}(,\\s+)?`), '')
        }
    }
    return newReason
}

export default {
    name: 'LegalInfoForm',
    components: {
        UploaderRow,
        LegalInfoReason
    },
    props: {
        defaults: {
            type: Object,
            required: false,
            default: () => ({})
        },
        clientType: {
            required: true
        },
        ivanHashId: {
            required: false,
            default: null
        }
    },
    data () {
        return {
            socialBenefits: this.defaults.socialBenefits || false,
            socialBenefitsField: this.defaults.socialBenefitsField || null,
            wage: this.defaults.wage || false,
            employment: this.defaults.employment || null,
            business: this.defaults.business || false,
            businessField: this.defaults.businessField || null,
            capital: this.defaults.capital || false,
            capitalResources: this.defaults.capitalResources || null,
            other: this.defaults.other || false,
            otherResources: this.defaults.otherResources || null,
            politician: this.defaults.politician || false,
            sanctionedBusiness: this.defaults.sanctionedBusiness || false,
            vop: this.defaults.vop || false,
            companyControlled: getTrueFalseNull(this.defaults.companyControlled),
            companyControlledName: this.defaults.companyControlledName || null,
            companyControlledAddress: this.defaults.companyControlledAddress || null,
            reason: resolveReason(this.defaults.reason || null, this),
            transactionsAmount: this.defaults.transactionsAmount || null,
            transactionsInterval: this.defaults.transactionsInterval || null,
            auditionHistory: getTrueFalseNull(this.defaults.auditionHistory),
            criminalActivity: getTrueFalseNull(this.defaults.criminalActivity),
            activateIB: this.defaults.activateIB || false,
            loadingUploads: false,
            uploads: [],
            removedUploads: [],
            reasons: this.defaults.reasons || []
        }
    },
    computed: {
        computedTransactionsAmount: {
            get () {
                return String(this.transactionsAmount ?? 0)
            },
            set (value) {
                clearTimeout(tm)
                this.transactionsAmount = value
                tm = setTimeout(() => {
                    this.transactionsAmount = this.formatNumber(this.transactionsAmount)
                }, 750)
            }
        },
        legalInfoUploads: {
            get () {
                return this.$store.state.uploads.legalInfoUploads
            },
            set (value) {
                this.$store.commit('setLegalInfoUploads', value)
            }
        },
        filteredInfoUploads () {
            return this.legalInfoUploads.filter(({ upload }) => !this.removedUploads.includes(upload))
        },
        transactionsIntervalOptions () {
            return [
                { value: 'daily', text: this.$t('legalInfo.intervalDaily') },
                { value: 'monthly', text: this.$t('legalInfo.intervalMonthly') },
                { value: 'quarterly', text: this.$t('legalInfo.intervalQuarterly') },
                { value: 'halfyearly', text: this.$t('legalInfo.intervalHalfyearly') },
                { value: 'yearly', text: this.$t('legalInfo.intervalYearly') },
                { value: 'less', text: this.$t('legalInfo.intervalLess') }
            ]
        },
        yesNoOptions () {
            return [
                {
                    value: true,
                    text: this.$t('app.yes')
                },
                {
                    value: false,
                    text: this.$t('app.no')
                }
            ]
        },
        dataHash () {
            const {
                loadingUploads,
                uploads,
                removedUploads,
                ...rest
            } = JSON.parse(JSON.stringify(this.$data))
            rest.uploads = [...this.filteredInfoUploads]
            return objectHash.sha1(rest)
        }
    },
    watch: {
        defaults (newValue) {
            this.$nextTick(() => {
                this.socialBenefits = this.defaults.socialBenefits || false
                this.socialBenefitsField = this.defaults.socialBenefitsField || null
                this.wage = this.defaults.wage || false
                this.employment = this.defaults.employment || null
                this.business = this.defaults.business || false
                this.businessField = this.defaults.businessField || null
                this.capital = this.defaults.capital || false
                this.capitalResources = this.defaults.capitalResources || null
                this.other = this.defaults.other || false
                this.otherResources = this.defaults.otherResources || null
                this.politician = this.defaults.politician || false
                this.sanctionedBusiness = this.defaults.sanctionedBusiness || false
                this.vop = this.defaults.vop || false
                this.companyControlled = getTrueFalseNull(this.defaults.companyControlled)
                this.companyControlledName = this.defaults.companyControlledName || null
                this.companyControlledAddress = this.defaults.companyControlledAddress || null
                this.reason = resolveReason(this.defaults.reason || null, this)
                this.transactionsAmount = this.defaults.transactionsAmount || null
                this.transactionsInterval = this.defaults.transactionsInterval || null
                this.auditionHistory = getTrueFalseNull(this.defaults.auditionHistory)
                this.criminalActivity = getTrueFalseNull(this.defaults.criminalActivity)
                this.activateIB = this.defaults.activateIB || false
                this.reasons = this.defaults.reasons || []
            })
        },
        dataHash (newValue) {
            const { loadingUploads, ...data } = JSON.parse(JSON.stringify({ ...this.$data }))
            if (!data.reason) {
                data.reason = sortedReasons.map(item => {
                    if (this.reasons.includes(item)) {
                        return item !== 'other' ? this.$t(`legalInfo.reasons.${item}`) : data.reason
                    }
                }).filter(Boolean).join(', ')
            }
            data.uploads = data.uploads.map(item => ({ upload: item._id, meta: item.meta }))
            this.$store.commit('setFormData', { name: 'legalInfo', content: data })
            this.$store.commit('setDataHash', { name: 'legalInfo', hash: newValue })
        }
    },
    mounted () {
        if (Array.isArray(this.defaults.uploads)) {
            const tmpMap = new Map(this.legalInfoUploads.map(item => [item.upload, item]))
            for (const item of this.defaults.uploads) {
                if (!tmpMap.has(item.upload)) {
                    tmpMap.set(item.upload, item)
                }
            }
            this.legalInfoUploads = [...tmpMap.values()]
        }
        this.$serverValidator.addHandler('legalInfo', this.onServerValidation)
        this.loadUploads()
            .then(() => {
                const data = JSON.parse(JSON.stringify({ ...this.$data }))
                if (!data.reason) {
                    data.reason = sortedReasons.map(item => {
                        if (this.reasons.includes(item)) {
                            return item !== 'other' ? this.$t(`legalInfo.reasons.${item}`) : data.reason
                        }
                    }).filter(Boolean).join(', ')
                }
                data.uploads = data.uploads.map(item => ({ upload: item._id, meta: item.meta }))
                this.$store.commit('setFormData', { name: 'legalInfo', content: data })
                this.$store.commit('setDataHash', { name: 'legalInfoOld', hash: ((Object.keys(this.defaults).length > 0) ? `${this.dataHash}` : null) })
                this.$store.commit('setDataHash', { name: 'legalInfo', hash: null })
            })
    },
    beforeDestroy () {
        this.$serverValidator.removeHandler('legalInfo', this.onServerValidation)
    },
    methods: {
        formatNumber (value) {
            // eslint-disable-next-line no-useless-escape
            const inputRate = `${value}`.replace(/\s/g, '').replace(',', '.').replace(/[,\.]{2,}/ig, '.')
            if (!isNaN(Number(inputRate)) && unrestrictedDecimalNumberRegex.test(inputRate.replace('.', ','))) {
                return Number(inputRate).toLocaleString('cs-CZ')
            }
            return value
        },
        onServerValidation (isValid) {
            if (!isValid) {
                this.$notify.error(this.$t('errors.someDataIsMissing'))
                this.$v.$touch()
            }
        },
        async loadUploads () {
            this.loadingUploads = true
            try {
                const { data } = await this.$api.clientSessionUploads.read()
                this.uploads = data.map(item => {
                    const found = this.legalInfoUploads.find(upload => upload.upload === item._id)
                    return {
                        ...item,
                        meta: found?.meta ?? null
                    }
                }).filter(item => item.meta)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('errors.cannotLoadUploads'))
            } finally {
                this.$nextTick(() => {
                    this.loadingUploads = false
                })
            }
        },
        async onUploadRemove (upload) {
            if (Object.keys(this.defaults).length > 0) {
                this.removedUploads.push(upload._id)
                return
            }
            this.loadingUploads = true
            try {
                await this.$api.clientSessionUploads.delete(upload._id)
                this.legalInfoUploads = this.legalInfoUploads.filter(item => (item.upload !== upload._id))
            } catch (error) {
                console.error(error)
            } finally {
                this.loadUploads()
            }
        },
        async onUploadUploaded (data, type) {
            const serverData = JSON.parse(data.serverId)
            if (Array.isArray(serverData) && serverData.length > 0) {
                const tmpMap = new Map(this.legalInfoUploads.map(item => [item.upload, item]))
                for (const data of serverData) {
                    if (!tmpMap.has(data._id)) {
                        tmpMap.set(data._id, {
                            upload: data._id,
                            meta: type
                        })
                    }
                }
                this.legalInfoUploads = [...tmpMap.values()]
                this.$nextTick(() => {
                    this.loadUploads()
                })
            }
        },
        onUploadRestore (upload) {
            this.removedUploads = this.removedUploads.filter(id => (id !== upload._id))
        },
        onSubmit () {
            this.reason = sortedReasons.map(item => {
                if (this.reasons.includes(item)) {
                    return item !== 'other' ? this.$t(`legalInfo.reasons.${item}`) : this.reason
                }
            }).filter(Boolean).join(', ')
            this.$v.$reset()
            this.$nextTick(() => {
                if (this.$v.$invalid) {
                    this.$notify.error(this.$t('errors.someDataIsMissing'))
                    this.$v.$touch()
                    return
                }
                const data = this.resolveUploads(JSON.parse(JSON.stringify(this.$data)))
                this.$emit('submit', data)
            })
        },
        resolveUploads (inputData) {
            const {
                loadingUploads,
                removedUploads,
                ...data
            } = inputData
            data.uploads = [...this.filteredInfoUploads]

            if (removedUploads.length > 0) {
                Promise.all(removedUploads.map(id => this.$api.clientSessionUploads.delete(id))).catch(() => ({}))
            }
            this.legalInfoUploads = [...this.filteredInfoUploads]

            const fieldsForUpload = ['capital', 'other', 'companyControlled']

            for (const field of fieldsForUpload) {
                const filteredUploads = this.legalInfoUploads.filter(item => item.meta === field)
                if (!data[field] && filteredUploads.length > 0) {
                    Promise.all(filteredUploads.map(item => this.$api.clientSessionUploads.delete(item.upload))).catch(() => ({}))
                    this.legalInfoUploads = this.legalInfoUploads.filter(item => item.meta !== field)
                }
            }
            for (const reason of sortedReasons) {
                const filteredUploads = this.legalInfoUploads.filter(item => item.meta === `reasons.${reason}`)
                if (!data.reasons.includes(reason) && filteredUploads.length > 0) {
                    Promise.all(filteredUploads.map(item => this.$api.clientSessionUploads.delete(item.upload))).catch(() => ({}))
                    this.legalInfoUploads = this.legalInfoUploads.filter(item => item.meta !== `reasons.${reason}`)
                }
            }
            data.legalInfoUploads = this.legalInfoUploads
            data.uploads = this.legalInfoUploads
            return data
        },
        onCancel () {
            this.$store.commit('setDataHash', { name: 'legalInfoOld', hash: null })
            this.$store.commit('setDataHash', { name: 'legalInfo', hash: null })
            this.$emit('cancel')
        }
    },
    validations: {
        filteredInfoUploads: {
            custom (value) {
                if (this.ivanHashId) {
                    return true
                }
                const localCopy = [...(value) ?? []]
                if (this.other && localCopy.filter(item => item.meta === 'other').length === 0) {
                    return false
                }
                if (this.companyControlled && localCopy.filter(item => item.meta === 'companyControlled').length === 0) {
                    return false
                }

                return true
            }
        },
        companyControlledName: {
            required: requiredIf((model) => model.companyControlled)
        },
        companyControlledAddress: {
            required: requiredIf((model) => model.companyControlled)
        },
        reason: {
            required
        },
        reasons: {
            custom () {
                return this.reasons.length > 0
            }
        },
        transactionsAmount: {
            custom: (value) => {
                return decimalNumberRegex.test(`${value}`.replace(/\s/g, '')) &&
                    /\b\d{3,}\b/.test(`${value}`.replace(/\s/g, ''))
            }
        },
        transactionsInterval: {
            required
        },
        moneyOrigin: {
            custom () {
                return (this.socialBenefits || this.wage || this.business || this.capital || this.other)
            }
        },
        socialBenefitsField: {
            required: requiredIf(model => model.socialBenefits === true),
            maxLength: maxLength(500)
        },
        employment: {
            required: requiredIf(model => model.wage === true),
            maxLength: maxLength(500)
        },
        businessField: {
            required: requiredIf(model => model.business === true),
            maxLength: maxLength(500)
        },
        capitalResources: {
            required: requiredIf(model => model.capital === true),
            maxLength: maxLength(500)
        },
        otherResources: {
            required: requiredIf(model => model.other === true),
            maxLength: maxLength(500)
        },
        vop: {
            custom (value) {
                return !!value
            }
        },
        auditionHistory: {
            required: requiredIf(function () { return this.clientType === 'PO' })
        },
        companyControlled: {
            required: requiredIf(function () { return this.clientType === 'PO' })
        },
        criminalActivity: {
            custom: (value) => !!value
        }
    }
}
</script>
